import { InventoryItem } from '../types/types'
import { Button, Col, Row, Image } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/free-solid-svg-icons'
import { Fragment } from 'react'

interface Props {
  item: InventoryItem
  empty: boolean
  adjustItems: any
  screenType: string
}

// * Shows the inventory card used on the Breakfast, Lunch, Dinner, Snack, and Drink screens
function ItemCard({ item, empty, adjustItems, screenType }: Props) {
  return (
    <Fragment>
      {screenType !== 'restaraunt' && item.Quantity > 0 && (
        <Row className='p-2'>
          <Col className='d-flex align-items-center justify-content-center'>
            <Button style={{ width: '80px' }} disabled={empty} variant='success' onClick={() => adjustItems(item.ItemName, 1)}>
              Order
            </Button>
          </Col>

          <Col className='d-flex align-items-center justify-content-center'>
            <strong>
              <div style={{ fontSize: '1.025em' }}>Quantity: {item.Quantity}</div>
            </strong>
          </Col>

          <Col className='d-flex align-items-center justify-content-center'>
            <Button style={{ width: '80px' }} disabled={empty} variant='danger' onClick={() => adjustItems(item.ItemName, -1)}>
              Remove
            </Button>
          </Col>
        </Row>
      )}

      <Row className='justify-content-center mb-2 mt-2'>
        <Col className='d-flex align-items-center justify-content-center' style={{ height: '190px', width: '190px' }}>
          <Image
            style={{ maxHeight: '170px', maxWidth: '250px' }}
            src={`${process.env.REACT_APP_IMAGES}${item.ItemFilename}`}
            alt={`${process.env.REACT_APP_IMAGES}$'broken.png'`}
          />
        </Col>
      </Row>

      {screenType !== 'restaraunt' && (
        <Row className='justify-content-center mb-2'>
          <Col className='d-flex p-2 align-items-center justify-content-center' style={{ fontSize: '1.10em' }}>
            {item.ItemName}
          </Col>
        </Row>
      )}

      {screenType !== 'restaraunt' && (
        <Row className='justify-content-center mb-2'>
          <Col>
            <Row className='mb-2'>
              <Col className='d-flex align-items-center justify-content-center p-2'>
                {item.Rating === 1 && (
                  <Fragment>
                    <FontAwesomeIcon icon={faStar} color='yellow' />
                    <FontAwesomeIcon icon={faStar} color='black' />
                    <FontAwesomeIcon icon={faStar} color='black' />
                    <FontAwesomeIcon icon={faStar} color='black' />
                    <FontAwesomeIcon icon={faStar} color='black' />
                  </Fragment>
                )}
                {item.Rating === 2 && (
                  <Fragment>
                    <FontAwesomeIcon icon={faStar} color='yellow' />
                    <FontAwesomeIcon icon={faStar} color='yellow' />
                    <FontAwesomeIcon icon={faStar} color='black' />
                    <FontAwesomeIcon icon={faStar} color='black' />
                    <FontAwesomeIcon icon={faStar} color='black' />
                  </Fragment>
                )}
                {item.Rating === 3 && (
                  <Fragment>
                    <FontAwesomeIcon icon={faStar} color='yellow' />
                    <FontAwesomeIcon icon={faStar} color='yellow' />
                    <FontAwesomeIcon icon={faStar} color='yellow' />
                    <FontAwesomeIcon icon={faStar} color='black' />
                    <FontAwesomeIcon icon={faStar} color='black' />
                  </Fragment>
                )}
                {item.Rating === 4 && (
                  <Fragment>
                    <FontAwesomeIcon icon={faStar} color='yellow' />
                    <FontAwesomeIcon icon={faStar} color='yellow' />
                    <FontAwesomeIcon icon={faStar} color='yellow' />
                    <FontAwesomeIcon icon={faStar} color='yellow' />
                    <FontAwesomeIcon icon={faStar} color='black' />
                  </Fragment>
                )}
                {item.Rating === 5 && (
                  <Fragment>
                    <FontAwesomeIcon icon={faStar} color='yellow' />
                    <FontAwesomeIcon icon={faStar} color='yellow' />
                    <FontAwesomeIcon icon={faStar} color='yellow' />
                    <FontAwesomeIcon icon={faStar} color='yellow' />
                    <FontAwesomeIcon icon={faStar} color='yellow' />
                  </Fragment>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      )}
    </Fragment>
  )
}

export default ItemCard
