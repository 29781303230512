import { Fragment, useState } from 'react'
import { Button, Col, Row, Image, Form } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar, faThumbsDown, faThumbsUp, faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { InventoryItem } from '../types/types'
import { useNavigate, useOutletContext } from 'react-router-dom'
import axios from 'axios'
import Swal from 'sweetalert2'

import Alert from './Alert'

interface Props {
  item: InventoryItem
  reload: any
}

type QtyBody = {
  id: number
  quantity: number
}

type ThumbBody = {
  id: number
  rating: number
}

function InventoryCard({ item, reload }: Props) {
  const [newItemQuantity, setNewItemQuantity] = useState<string>('')

  const { jwtToken } = useOutletContext<any>()
  const navigate = useNavigate()

  function handleEditItem(i: string) {
    navigate(`/edit/${i}`, { replace: true })
  }

  function handleNewQuantity(value: string) {
    setNewItemQuantity(value)
  }

  async function handleThumb(id: number, rating: number, direction: string) {
    if (jwtToken === '') {
      navigate('/login')
      return
    }

    if (rating === 1 && direction === 'down') {
      return
    }

    if (rating === 5 && direction === 'up') {
      return
    }

    if (direction === 'up') {
      rating = rating + 1
    } else {
      rating = rating - 1
    }

    let Body: ThumbBody = {
      id: id,
      rating: rating,
    }

    try {
      await axios.post(
        `${
          process.env.NODE_ENV === 'development' ? process.env.REACT_APP_BACKEND_DEV : process.env.REACT_APP_BACKEND_PROD
        }/admin/rating`,
        Body,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + jwtToken,
          },
          withCredentials: true,
        }
      )

      reload(true)
    } catch (err: any) {
      Alert(err)
    }
  }

  async function handleItemSave(id: number) {
    if (jwtToken === '') {
      navigate('/login')
      return
    }

    // determine if newItemQuantity is a number >= 0
    if (isNaN(parseInt(newItemQuantity))) {
      Alert('qty must be numeric')
      return
    }

    if (parseInt(newItemQuantity) < 0) {
      Alert('qty value must be a number >= 0')
      return
    }

    let Body: QtyBody = {
      id: id,
      quantity: parseInt(newItemQuantity),
    }

    try {
      await axios.post(
        `${
          process.env.NODE_ENV === 'development' ? process.env.REACT_APP_BACKEND_DEV : process.env.REACT_APP_BACKEND_PROD
        }/admin/inventory`,
        Body,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + jwtToken,
          },
          withCredentials: true,
        }
      )

      Swal.fire({
        icon: 'success',
        text: 'Quantity Updated',
        timer: 1250,
        timerProgressBar: true,
        position: 'top',
      })
    } catch (err: any) {
      Alert(err)
    }
  }

  return (
    <Fragment>
      <Row className='mt-2'>
        <Col md={3}>
          <Row className='justify-content-md-center'>
            <Image
              style={{ maxHeight: '150px', width: 'auto' }}
              src={`${process.env.REACT_APP_IMAGES}${item.ItemFilename}`}
              alt={`${process.env.REACT_APP_IMAGES}$'broken.png'`}
            />
          </Row>
        </Col>

        <Col md={7}>
          <Row className='justify-content-md-center' style={{ fontSize: '1.25em' }}>
            {item.ItemName}
          </Row>

          <Row className='justify-content-md-center mt-2'>
            <Col className='d-flex align-items-center justify-content-center'>
              <Form.Control
                style={{ width: '50px' }}
                defaultValue={item.Quantity}
                onChange={(e) => handleNewQuantity(e.target.value)}
              />
            </Col>
          </Row>

          <Row className='justify-content-md-center mt-2'>
            <Col className='d-flex align-items-center justify-content-center'>
              {item.Rating === 1 && (
                <Fragment>
                  <FontAwesomeIcon icon={faStar} color='yellow' />
                  <FontAwesomeIcon icon={faStar} color='black' />
                  <FontAwesomeIcon icon={faStar} color='black' />
                  <FontAwesomeIcon icon={faStar} color='black' />
                  <FontAwesomeIcon icon={faStar} color='black' />
                </Fragment>
              )}
              {item.Rating === 2 && (
                <Fragment>
                  <FontAwesomeIcon icon={faStar} color='yellow' />
                  <FontAwesomeIcon icon={faStar} color='yellow' />
                  <FontAwesomeIcon icon={faStar} color='black' />
                  <FontAwesomeIcon icon={faStar} color='black' />
                  <FontAwesomeIcon icon={faStar} color='black' />
                </Fragment>
              )}
              {item.Rating === 3 && (
                <Fragment>
                  <FontAwesomeIcon icon={faStar} color='yellow' />
                  <FontAwesomeIcon icon={faStar} color='yellow' />
                  <FontAwesomeIcon icon={faStar} color='yellow' />
                  <FontAwesomeIcon icon={faStar} color='black' />
                  <FontAwesomeIcon icon={faStar} color='black' />
                </Fragment>
              )}
              {item.Rating === 4 && (
                <Fragment>
                  <FontAwesomeIcon icon={faStar} color='yellow' />
                  <FontAwesomeIcon icon={faStar} color='yellow' />
                  <FontAwesomeIcon icon={faStar} color='yellow' />
                  <FontAwesomeIcon icon={faStar} color='yellow' />
                  <FontAwesomeIcon icon={faStar} color='black' />
                </Fragment>
              )}
              {item.Rating === 5 && (
                <Fragment>
                  <FontAwesomeIcon icon={faStar} color='yellow' />
                  <FontAwesomeIcon icon={faStar} color='yellow' />
                  <FontAwesomeIcon icon={faStar} color='yellow' />
                  <FontAwesomeIcon icon={faStar} color='yellow' />
                  <FontAwesomeIcon icon={faStar} color='yellow' />
                </Fragment>
              )}
            </Col>
          </Row>

          <Row className='mt-2'>
            <Col className='d-flex align-items-center justify-content-center'>
              Br:&nbsp;
              {item.Breakfast ? (
                <FontAwesomeIcon icon={faCheckCircle} color='green' />
              ) : (
                <FontAwesomeIcon icon={faCheckCircle} color='black' />
              )}
            </Col>

            <Col>
              Lu:&nbsp;
              {item.Lunch ? (
                <FontAwesomeIcon icon={faCheckCircle} color='green' />
              ) : (
                <FontAwesomeIcon icon={faCheckCircle} color='black' />
              )}
            </Col>

            <Col>
              Di:&nbsp;
              {item.Dinner ? (
                <FontAwesomeIcon icon={faCheckCircle} color='green' />
              ) : (
                <FontAwesomeIcon icon={faCheckCircle} color='black' />
              )}
            </Col>

            <Col>
              Sn:&nbsp;
              {item.Snack ? (
                <FontAwesomeIcon icon={faCheckCircle} color='green' />
              ) : (
                <FontAwesomeIcon icon={faCheckCircle} color='black' />
              )}
            </Col>

            <Col>
              Dr:&nbsp;
              {item.Drink ? (
                <FontAwesomeIcon icon={faCheckCircle} color='green' />
              ) : (
                <FontAwesomeIcon icon={faCheckCircle} color='black' />
              )}
            </Col>

            <Col>
              Re:&nbsp;
              {item.Restaraunt ? (
                <FontAwesomeIcon icon={faCheckCircle} color='green' />
              ) : (
                <FontAwesomeIcon icon={faCheckCircle} color='black' />
              )}
            </Col>
          </Row>
        </Col>

        <Col md={2}>
          <Row className='mt-2 p-0 m-0'>
            <Col>
              <Button
                variant='danger'
                disabled={item.Rating === 0 ? true : false}
                onClick={() => handleThumb(parseInt(item.ID), item.Rating, 'down')}
              >
                <FontAwesomeIcon icon={faThumbsDown} />
              </Button>
            </Col>

            <Col>
              <Button
                variant='success'
                disabled={item.Rating === 5 ? true : false}
                onClick={() => handleThumb(parseInt(item.ID), item.Rating, 'up')}
              >
                <FontAwesomeIcon icon={faThumbsUp} />
              </Button>
            </Col>
          </Row>

          <Row className='justify-content-md-center mt-2'>
            <Col>
              <Button variant='dark' style={{ width: '150px' }} onClick={() => handleEditItem(item.ID)}>
                Edit Details
              </Button>
            </Col>
          </Row>

          <Row className='justify-content-md-center mt-2'>
            <Col>
              <Button style={{ width: '150px' }} onClick={() => handleItemSave(parseInt(item.ID))}>
                Save Changes
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Fragment>
  )
}

export default InventoryCard
