import { Fragment, useEffect, useState } from 'react'
import { Container, Row, Col, Form, Spinner } from 'react-bootstrap'
import { InventoryItem } from '../types/types'
import axios from 'axios'

import Alert from './Alert'
import InventoryCard from './InventoryCard'
import { useNavigate, useOutletContext } from 'react-router-dom'

interface Props {
  screenType: string
  title: string
}

function Inventory({ screenType, title }: Props) {
  const [mealItems, setMealItems] = useState<InventoryItem[]>([])
  const [availableMealItems, setAvailableMealItems] = useState<InventoryItem[]>([])
  const [unavailableMealItems, setUnavailableMealItems] = useState<InventoryItem[]>([])
  const [hideOneStar, setHideOneStar] = useState<boolean>(true) // show/hide 1-star rated items
  const [showBreakfast, setShowBreakfast] = useState(true)
  const [showLunch, setShowLunch] = useState(true)
  const [showDinner, setShowDinner] = useState(true)
  const [showSnack, setShowSnack] = useState(false)
  const [showDrink, setShowDrink] = useState(false)
  const [showRestaraunt, setShowRestaraunt] = useState(false)
  const [showSpinner, setShowSpinner] = useState<boolean>(false)
  const [reload, setReload] = useState<boolean>(true)

  const { jwtToken } = useOutletContext<any>()
  const navigate = useNavigate()

  useEffect(() => {
    async function getInventoryItems() {
      if (jwtToken === '') {
        navigate('/login')
        return
      }

      // setShowSpinner(true)

      try {
        const resp = await axios.get(
          `${
            process.env.NODE_ENV === 'development' ? process.env.REACT_APP_BACKEND_DEV : process.env.REACT_APP_BACKEND_PROD
          }/admin/${screenType}`,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + jwtToken,
            },
          }
        )

        let items: InventoryItem[] = []
        let available: InventoryItem[] = []
        let unavailable: InventoryItem[] = []

        // populate the main list
        for await (const v of resp.data) {
          items.push({
            ID: v.id,
            ItemName: v.item_name,
            Quantity: v.quantity,
            ItemFilename: v.item_filename,
            Breakfast: v.breakfast,
            Lunch: v.lunch,
            Dinner: v.dinner,
            Snack: v.snack,
            Drink: v.drink,
            Restaraunt: v.restaraunt,
            Brand: v.brand,
            Store: v.store,
            Rating: v.rating,
            CreatedAt: new Date(v.created_at),
            UpdatedAt: new Date(v.updated_at),
          })
        }

        items.sort(function (a, b) {
          var textA = a.ItemName.toUpperCase()
          var textB = b.ItemName.toUpperCase()
          return textA < textB ? -1 : textA > textB ? 1 : 0
        })

        for (let i = 0; i < items.length; i++) {
          if (
            items[i].Quantity > 0 &&
            items[i].Rating !== 1 &&
            (items[i].Breakfast === true || items[i].Lunch === true || items[i].Dinner === true)
          ) {
            available.push({
              ID: items[i].ID,
              ItemName: items[i].ItemName,
              Quantity: items[i].Quantity,
              ItemFilename: items[i].ItemFilename,
              Breakfast: items[i].Breakfast,
              Lunch: items[i].Lunch,
              Dinner: items[i].Dinner,
              Snack: items[i].Snack,
              Drink: items[i].Drink,
              Restaraunt: items[i].Restaraunt,
              Brand: items[i].Brand,
              Store: items[i].Store,
              Rating: items[i].Rating,
              CreatedAt: items[i].CreatedAt,
              UpdatedAt: items[i].UpdatedAt,
            })
          } else if (
            items[i].Quantity === 0 &&
            items[i].Rating !== 1 &&
            (items[i].Breakfast === true || items[i].Lunch === true || items[i].Dinner === true)
          ) {
            unavailable.push({
              ID: items[i].ID,
              ItemName: items[i].ItemName,
              Quantity: items[i].Quantity,
              ItemFilename: items[i].ItemFilename,
              Breakfast: items[i].Breakfast,
              Lunch: items[i].Lunch,
              Dinner: items[i].Dinner,
              Snack: items[i].Snack,
              Drink: items[i].Drink,
              Restaraunt: items[i].Restaraunt,
              Brand: items[i].Brand,
              Store: items[i].Store,
              Rating: items[i].Rating,
              CreatedAt: items[i].CreatedAt,
              UpdatedAt: items[i].UpdatedAt,
            })
          }
        }

        setMealItems(items)
        setAvailableMealItems(available)
        setUnavailableMealItems(unavailable)
        setHideOneStar(true)
        setReload(false)
      } catch (error: any) {
        Alert(error)
      }
      setShowSpinner(false)
    }

    if (reload) {
      getInventoryItems()
    }
  }, [screenType, jwtToken, navigate, reload])

  useEffect(() => {
    if (jwtToken === '') {
      navigate('/login')
      return
    }

    let available: InventoryItem[] = []
    let unavailable: InventoryItem[] = []

    for (let i = 0; i < mealItems.length; i++) {
      let okToAdd = false

      if (showBreakfast && mealItems[i].Breakfast === true) {
        okToAdd = true
      }
      if (showLunch && mealItems[i].Lunch === true) {
        okToAdd = true
      }
      if (showDinner && mealItems[i].Dinner === true) {
        okToAdd = true
      }
      if (showSnack && mealItems[i].Snack === true) {
        okToAdd = true
      }
      if (showDrink && mealItems[i].Drink === true) {
        okToAdd = true
      }
      if (showRestaraunt && mealItems[i].Restaraunt === true) {
        okToAdd = true
      }

      if (hideOneStar && mealItems[i].Rating === 1) {
        okToAdd = false
      }

      if (okToAdd) {
        if (mealItems[i].Quantity > 0) {
          available.push({
            ID: mealItems[i].ID,
            ItemName: mealItems[i].ItemName,
            Quantity: mealItems[i].Quantity,
            ItemFilename: mealItems[i].ItemFilename,
            Breakfast: mealItems[i].Breakfast,
            Lunch: mealItems[i].Lunch,
            Dinner: mealItems[i].Dinner,
            Snack: mealItems[i].Snack,
            Drink: mealItems[i].Drink,
            Restaraunt: mealItems[i].Restaraunt,
            Brand: mealItems[i].Brand,
            Store: mealItems[i].Store,
            Rating: mealItems[i].Rating,
            CreatedAt: mealItems[i].CreatedAt,
            UpdatedAt: mealItems[i].UpdatedAt,
          })
        } else {
          unavailable.push({
            ID: mealItems[i].ID,
            ItemName: mealItems[i].ItemName,
            Quantity: mealItems[i].Quantity,
            ItemFilename: mealItems[i].ItemFilename,
            Breakfast: mealItems[i].Breakfast,
            Lunch: mealItems[i].Lunch,
            Dinner: mealItems[i].Dinner,
            Snack: mealItems[i].Snack,
            Drink: mealItems[i].Drink,
            Restaraunt: mealItems[i].Restaraunt,
            Brand: mealItems[i].Brand,
            Store: mealItems[i].Store,
            Rating: mealItems[i].Rating,
            CreatedAt: mealItems[i].CreatedAt,
            UpdatedAt: mealItems[i].UpdatedAt,
          })
        }
      }
    }

    setAvailableMealItems(available)
    setUnavailableMealItems(unavailable)
  }, [showBreakfast, showLunch, showDinner, showSnack, showDrink, showRestaraunt, hideOneStar, mealItems, jwtToken, navigate])

  return (
    <Container>
      <Row>
        <Col>
          <h3>{title}</h3>
        </Col>

        <Col>
          <Form.Check
            type='switch'
            label='Hide 1 Star Ratings?'
            checked={hideOneStar}
            onChange={() => setHideOneStar(!hideOneStar)}
          />
        </Col>

        <Col>
          <Form.Check
            id='breakfast'
            type='checkbox'
            label='Breakfast'
            checked={showBreakfast}
            onChange={() => setShowBreakfast(!showBreakfast)}
          />
        </Col>

        <Col>
          <Form.Check id='lunch' type='checkbox' label='Lunch' checked={showLunch} onChange={() => setShowLunch(!showLunch)} />
        </Col>

        <Col>
          <Form.Check
            id='dinner'
            type='checkbox'
            label='Dinner'
            checked={showDinner}
            onChange={() => setShowDinner(!showDinner)}
          />
        </Col>

        <Col>
          <Form.Check id='snack' type='checkbox' label='Snack' checked={showSnack} onChange={() => setShowSnack(!showSnack)} />
        </Col>

        <Col>
          <Form.Check id='drink' type='checkbox' label='Drink' checked={showDrink} onChange={() => setShowDrink(!showDrink)} />
        </Col>

        <Col>
          <Form.Check
            id='restaraunt'
            type='checkbox'
            label='Restaraunt'
            checked={showRestaraunt}
            onChange={() => setShowRestaraunt(!showRestaraunt)}
          />
        </Col>
      </Row>

      <hr />

      {showSpinner && (
        <Spinner animation='border' role='status'>
          <span className='visually-hidden'>Loading...</span>
        </Spinner>
      )}

      {!showSpinner && (
        <Fragment>
          <Container fluid>
            <div style={{ overflowX: 'hidden', overflowY: 'auto', height: 'calc(100vh - 200px)', border: '1px solid' }}>
              <Row style={{ textAlign: 'center' }}>
                <Col style={{ backgroundColor: '#009900', color: '#FFFFFF' }}>
                  <strong>Showing {availableMealItems.length} Available Items</strong>
                </Col>
              </Row>

              {availableMealItems.map((value) => (
                <div key={value.ID} className='green-back'>
                  <InventoryCard item={value} reload={setReload} />
                </div>
              ))}

              <Row style={{ textAlign: 'center' }}>
                <Col style={{ backgroundColor: '#990000', color: '#FFFF00' }}>
                  <strong>Showing {unavailableMealItems.length} Unavailable Items</strong>
                </Col>
              </Row>

              {unavailableMealItems.map((value) => (
                <div key={value.ID} className='red-back'>
                  <InventoryCard item={value} reload={setReload} />
                </div>
              ))}
            </div>
          </Container>
        </Fragment>
      )}
    </Container>
  )
}

export default Inventory
