import React, { Fragment, useEffect, useState } from 'react'
import { Container, Row, Col, Spinner, Button } from 'react-bootstrap'
import { InventoryItem } from '../types/types'
import axios from 'axios'
import Swal from 'sweetalert2'
import { useNavigate, useOutletContext } from 'react-router-dom'

import Alert from './Alert'
import ItemCard from './ItemCard'

interface Props {
  screenType: string
  title: string
}

interface Foods {
  [key: string]: number
}

// * The layout of the menu type screens
function Meal({ screenType, title }: Props) {
  const [availableMealItems, setAvailableMealItems] = useState<InventoryItem[]>([])
  const [unavailableMealItems, setUnavailableMealItems] = useState<InventoryItem[]>([])
  const [selectedItemName, setSelectedItemName] = useState<Foods>({})
  const [prettySelected, setPrettySelected] = useState<string>('')
  const [showSpinner, setShowSpinner] = useState<boolean>(false)
  const [itemsChanged, setItemsChanged] = useState<boolean>(false)

  const { jwtToken } = useOutletContext<any>()
  const navigate = useNavigate()

  useEffect(() => {
    if (itemsChanged) {
      // make a pretty version
      let pretty: string = ''

      // see if the current food list has any key values indicating something was ordered
      if (Object.keys(selectedItemName).length > 0) {
        for (const item in selectedItemName) {
          pretty = pretty + `${item.replaceAll('_', ' ')} (${selectedItemName[item]}), `
        }
        pretty = pretty.slice(0, -2)
      }
      setPrettySelected(pretty)
    }
  }, [itemsChanged, selectedItemName])

  useEffect(() => {
    async function getMealItems() {
      if (jwtToken === '') {
        navigate('/login')
        return
      }

      setShowSpinner(true)
      try {
        // first get all the items and store it in an array that won't change unless we refresh the page.
        const resp = await axios.get(
          `${
            process.env.NODE_ENV === 'development' ? process.env.REACT_APP_BACKEND_DEV : process.env.REACT_APP_BACKEND_PROD
          }/admin/meal/${screenType}`,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + jwtToken,
            },
          }
        )

        let items: InventoryItem[] = []
        let available: InventoryItem[] = []
        let unavailable: InventoryItem[] = []

        for await (const v of resp.data) {
          items.push({
            ID: v.id,
            ItemName: v.item_name,
            Quantity: v.quantity,
            ItemFilename: v.item_filename,
            Breakfast: v.breakfast,
            Lunch: v.lunch,
            Dinner: v.dinner,
            Snack: v.snack,
            Drink: v.drink,
            Restaraunt: v.restaraunt,
            Brand: v.brand,
            Store: v.store,
            Rating: v.rating,
            CreatedAt: new Date(v.created_at),
            UpdatedAt: new Date(v.updated_at),
          })
        }

        items.sort(function (a, b) {
          var textA = a.ItemName.toUpperCase()
          var textB = b.ItemName.toUpperCase()
          return textA < textB ? -1 : textA > textB ? 1 : 0
        })

        // then push those same values into the available items and unavailable items
        switch (screenType) {
          case 'breakfast':
            available = [...items]
              .filter((item) => item.Quantity > 0 && item.Rating > 1)
              .filter((item) => item.Breakfast === true)

            unavailable = [...items]
              .filter((item) => item.Quantity === 0 && item.Rating > 1)
              .filter((item) => item.Breakfast === true)
            break
          case 'lunch':
            available = [...items].filter((item) => item.Quantity > 0 && item.Rating > 1).filter((item) => item.Lunch === true)
            unavailable = [...items]
              .filter((item) => item.Quantity === 0 && item.Rating > 1)
              .filter((item) => item.Lunch === true)
            break
          case 'dinner':
            available = [...items].filter((item) => item.Quantity > 0 && item.Rating > 1).filter((item) => item.Dinner === true)
            unavailable = [...items]
              .filter((item) => item.Quantity === 0 && item.Rating > 1)
              .filter((item) => item.Dinner === true)
            break
          case 'snack':
            available = [...items].filter((item) => item.Quantity > 0 && item.Rating > 1).filter((item) => item.Snack === true)
            unavailable = [...items]
              .filter((item) => item.Quantity === 0 && item.Rating > 1)
              .filter((item) => item.Snack === true)
            break
          case 'drink':
            available = [...items].filter((item) => item.Quantity > 0 && item.Rating > 1).filter((item) => item.Drink === true)
            unavailable = [...items]
              .filter((item) => item.Quantity === 0 && item.Rating > 1)
              .filter((item) => item.Drink === true)
            break
          case 'restaraunt':
            available = [...items]
              .filter((item) => item.Quantity > 0 && item.Rating > 1)
              .filter((item) => item.Restaraunt === true)
            unavailable = [...items]
              .filter((item) => item.Quantity === 0 && item.Rating > 1)
              .filter((item) => item.Restaraunt === true)
            break
          default:
            Swal.fire({
              title: 'No Data',
              icon: 'error',
              text: 'No data for a menu type that is invalid.',
            })
            break
        }

        setAvailableMealItems(available)
        setUnavailableMealItems(unavailable)
      } catch (error: any) {
        Alert(error)
      }
      setShowSpinner(false)
    }

    getMealItems()
  }, [screenType, jwtToken, navigate])

  function handleCancelClick() {
    setSelectedItemName({})
    setItemsChanged(true)
  }

  async function handleAddOrder() {
    let body = {}
    body = { data: `${prettySelected}` }

    if (jwtToken === '') {
      navigate('/login')
      return
    }

    try {
      await axios.put(
        `${
          process.env.NODE_ENV === 'development' ? process.env.REACT_APP_BACKEND_DEV : process.env.REACT_APP_BACKEND_PROD
        }/admin/meal/${screenType}`,
        body,
        {
          headers: {
            Authorization: 'Bearer ' + jwtToken,
          },
          withCredentials: true,
        }
      )
      handleCancelClick()
    } catch (error: any) {
      Alert(error)
    }
  }

  function adjustItems(selectedItem: string, direction: number) {
    // store the items as key/value pairs in an object.
    // since we are storing the name as the key, replace spaces with underscores

    let newName: string = selectedItem.replaceAll(' ', '_')
    let qty = 0

    if (!selectedItemName.hasOwnProperty(newName) && direction === 1) {
      // add a new item with a value of 1
      setSelectedItemName({ ...selectedItemName, [newName]: 1 })
      setItemsChanged(true)
    } else if (selectedItemName.hasOwnProperty(newName) && direction === 1) {
      // item already exists increment by 1
      qty = selectedItemName[newName] + 1
      setSelectedItemName({ ...selectedItemName, [newName]: qty })
      setItemsChanged(true)
    } else if (!selectedItemName.hasOwnProperty(newName) && direction === -1) {
      // item does not exist, and you tried to remove one.
      // do nothing.
    } else if (selectedItemName.hasOwnProperty(newName) && direction === -1) {
      // item does exist and we want to remove 1
      // if qty would go to zero, remove the key otherwise decrement 1
      qty = selectedItemName[newName] - 1
      if (qty > 0) {
        setSelectedItemName({ ...selectedItemName, [newName]: qty })
      } else {
        let tempState: Foods = JSON.parse(JSON.stringify(selectedItemName))
        delete tempState[newName]
        setSelectedItemName(JSON.parse(JSON.stringify(tempState)))
        setItemsChanged(true)
      }
    }
  }

  return (
    <Container>
      <Row>
        <Col md='auto'>
          <h3>{title}</h3>
        </Col>
        <Col md='auto'>
          {prettySelected.length > 0 && (
            <Button style={{ width: '160px' }} variant='success' onClick={handleAddOrder}>
              Confirm Order
            </Button>
          )}
        </Col>
        <Col md='auto'>
          {prettySelected.length > 0 && (
            <Button style={{ width: '160px' }} variant='danger' onClick={handleCancelClick}>
              Cancel Order
            </Button>
          )}
        </Col>
      </Row>

      {screenType !== 'restaraunt' && (
        <Fragment>
          <Row className='mt-4 mb-4'>
            <Col md='auto'>
              <strong>Ordered:</strong>{' '}
              {prettySelected.length > 0 && (
                <span style={{ padding: '4px', backgroundColor: 'black', color: 'yellow', borderRadius: '5px' }}>
                  <em>{prettySelected}</em>
                </span>
              )}
            </Col>
          </Row>
        </Fragment>
      )}

      {showSpinner && (
        <Spinner animation='border' role='status'>
          <span className='visually-hidden'>Loading...</span>
        </Spinner>
      )}

      {!showSpinner && (
        <Fragment>
          <div
            style={{
              overflowX: 'hidden',
              overflowY: 'auto',
              height: 'calc(100vh - 220px)',
              border: '1px solid',
            }}
          >
            <Row style={{ textAlign: 'center' }}>
              <Col style={{ backgroundColor: '#009900', color: '#FFFFFF' }}>
                <strong>Showing {availableMealItems.length} Available Items</strong>
              </Col>
            </Row>

            <Row sm={1} md={3} lg={3} className='g-2'>
              {availableMealItems.map((value) => (
                <React.Fragment key={`${value.ItemName}${value.ID}`}>
                  <Col className='green-back' key={value.ID}>
                    <ItemCard
                      key={value.ID}
                      item={value}
                      empty={value.Quantity === 0 ? true : false}
                      adjustItems={adjustItems}
                      screenType={screenType}
                    />
                  </Col>
                </React.Fragment>
              ))}
            </Row>

            {screenType !== 'restaraunt' && (
              <Fragment>
                <Row className='mt-2' style={{ textAlign: 'center' }}>
                  <Col style={{ backgroundColor: '#990000', color: '#FFFF00' }}>
                    <strong>Showing {unavailableMealItems.length} Unavailable Items</strong>
                  </Col>
                </Row>

                <Row sm={1} md={3} lg={3} className='g-2'>
                  {unavailableMealItems.map((value) => (
                    <React.Fragment key={`${value.ItemName}${value.ID}`}>
                      <Col key={value.ID} className='red-back'>
                        <ItemCard
                          key={value.ID}
                          item={value}
                          empty={value.Quantity === 0 ? true : false}
                          adjustItems={adjustItems}
                          screenType={screenType}
                        />
                      </Col>
                    </React.Fragment>
                  ))}
                </Row>
              </Fragment>
            )}
          </div>
        </Fragment>
      )}
    </Container>
  )
}

export default Meal
