import { useRouteError } from 'react-router-dom'
import { Container, Row, Col } from 'react-bootstrap'

export default function ErrorPage() {
  const error: any = useRouteError()

  return (
    <Container>
      <Row>
        <Col className='col-md-6 offset-md-3'>
          <h1 className='mt-3'>Ooops!</h1>
          <p>Sorry, an unexpected error has occurred.</p>
          <p>
            <em>{error.statusText || error.message}</em>
          </p>
        </Col>
      </Row>
    </Container>

  )
}