import { Fragment, useCallback, useMemo, useState } from 'react'
import { Container, Row, Col, Nav } from 'react-bootstrap'
import { Outlet, Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import Alert from './components/Alert'

function App() {
  const [jwtToken, setJwtToken] = useState<string>('')
  const [tickInterval, setTickinterval] = useState<any>()

  const navigate = useNavigate()

  const toggleRefresh = useCallback(
    (status: boolean) => {
      async function refresh() {
        try {
          const resp = await axios.get(
            `${
              process.env.NODE_ENV === 'development' ? process.env.REACT_APP_BACKEND_DEV : process.env.REACT_APP_BACKEND_PROD
            }/refresh`,
            {
              headers: {
                'Content-Type': 'application/json',
              },
              withCredentials: true,
            }
          )

          if (resp.data.access_token) {
            setJwtToken(resp.data.access_token)
            toggleRefresh(true)
          }
        } catch (error: any) {
          Alert(error)
        }
      }
      if (status) {
        let i: any = setInterval(() => {
          refresh()
        }, 600000)
        setTickinterval(i)
      } else {
        setTickinterval(null)
        clearInterval(tickInterval)
      }
    },
    [tickInterval]
  )

  const logout = async () => {
    try {
      await axios.get(
        `${
          process.env.NODE_ENV === 'development' ? process.env.REACT_APP_BACKEND_DEV : process.env.REACT_APP_BACKEND_PROD
        }/logout`,
        {
          headers: {
            'Content-Type': 'application/json',
          },
          withCredentials: true,
        }
      )

      setJwtToken('')
      toggleRefresh(false)
    } catch (error: any) {
      Alert(error)
    }
    navigate('/login')
  }

  useMemo(async () => {
    if (jwtToken === '') {
      try {
        const resp = await axios.get(
          `${
            process.env.NODE_ENV === 'development' ? process.env.REACT_APP_BACKEND_DEV : process.env.REACT_APP_BACKEND_PROD
          }/refresh`,
          {
            headers: {
              'Content-Type': 'application/json',
            },
            withCredentials: true,
          }
        )

        if (resp.data.access_token) {
          setJwtToken(resp.data.access_token)
          toggleRefresh(true)
        }
      } catch (error: any) {
        Alert(error)
      }
    }
  }, [jwtToken, toggleRefresh])

  return (
    <Container fluid>
      <Row>
        <Col>
          <h1 className='mt-2'>Web Menu</h1>
        </Col>
        <Col className='text-end'>
          <Fragment>
            {jwtToken === '' ? (
              <Link to='/login'>
                <span className='badge bg-success mt-3 p-2'>Login</span>
              </Link>
            ) : (
              <a href='#!' onClick={logout}>
                <span className='badge bg-danger mt-3 p-2'>Logout</span>
              </a>
            )}
          </Fragment>
        </Col>
      </Row>

      <Row>
        <hr />
      </Row>

      <Row>
        <Col md='auto'>
          <Nav>
            <div className='list-group'>
              <Link to='/' className='list-group-item list-group-item-action'>
                Home
              </Link>

              <Link to='/choices' className='list-group-item list-group-item-action'>
                Choices
              </Link>

              {jwtToken !== '' && (
                <Fragment>
                  <Link to='/meal/breakfast' className='list-group-item list-group-item-action'>
                    Breakfast
                  </Link>

                  <Link to='/meal/lunch' className='list-group-item list-group-item-action'>
                    Lunch
                  </Link>

                  <Link to='/meal/dinner' className='list-group-item list-group-item-action'>
                    Dinner
                  </Link>

                  <Link to='/meal/snack' className='list-group-item list-group-item-action'>
                    Snack
                  </Link>

                  <Link to='/meal/drink' className='list-group-item list-group-item-action'>
                    Drink
                  </Link>

                  <Link to='/meal/restaraunt' className='list-group-item list-group-item-action'>
                    Restaraunt
                  </Link>

                  <Link to='/inventory' className='list-group-item list-group-item-action'>
                    Inventory
                  </Link>
                </Fragment>
              )}
            </div>
          </Nav>
        </Col>

        <Col className='col-md-10'>
          <Outlet context={{ jwtToken, setJwtToken, toggleRefresh }} />
        </Col>
      </Row>
    </Container>
  )
}

export default App
