import { Container, Row, Col, Card, ListGroup, Button } from 'react-bootstrap'
import axios from 'axios'
import { Fragment, useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import Alert from './Alert'
import { useOutletContext } from 'react-router-dom'

function Choices() {
  const [breakfastChoice, setBreakfastChoice] = useState([])
  const [lunchChoice, setLunchChoice] = useState([])
  const [dinnerChoice, setDinnerChoice] = useState([])
  const [snackChoice, setSnackChoice] = useState([])
  const [cleared, setCleared] = useState(false)

  const { jwtToken } = useOutletContext<any>()

  useEffect(() => {
    async function getChoices() {
      try {
        const resp = await axios.get(
          `${
            process.env.NODE_ENV === 'development' ? process.env.REACT_APP_BACKEND_DEV : process.env.REACT_APP_BACKEND_PROD
          }/choices`
        )

        if (resp.data) {
          let a = resp.data.breakfast_choice.replaceAll('"', '')
          let b = a.split(',')
          setBreakfastChoice(b)

          a = resp.data.lunch_choice.replaceAll('"', '')
          b = a.split(',')
          setLunchChoice(b)

          a = resp.data.dinner_choice.replaceAll('"', '')
          b = a.split(',')
          setDinnerChoice(b)

          a = resp.data.snack_choice.replaceAll('"', '')
          b = a.split(',')
          setSnackChoice(b)
        } else {
          Swal.fire({
            title: 'No Data',
            icon: 'error',
            text: 'No data was returned from the server.',
          })
        }
      } catch (error: any) {
        Alert(error)
      }
    }

    getChoices()
  }, [cleared])

  async function handleEatenClick(mealEaten: string) {
    if (mealEaten === 'breakfast') {
      try {
        await axios.delete(
          `${
            process.env.NODE_ENV === 'development' ? process.env.REACT_APP_BACKEND_DEV : process.env.REACT_APP_BACKEND_PROD
          }/choices`
        )
        setCleared(true)
      } catch (error: any) {
        Alert(error)
      }
    }
  }

  return (
    <Container>
      <Card className='mt-2 text-center choice-back'>
        <Card.Body>
          <Card.Title>Breakfast</Card.Title>
          <ListGroup>
            {breakfastChoice.map((item, index) => (
              <ListGroup.Item key={index}>{item}</ListGroup.Item>
            ))}
          </ListGroup>
        </Card.Body>
      </Card>

      <Card className='mt-2 text-center choice-back'>
        <Card.Body>
          <Card.Title>Lunch</Card.Title>
          <ListGroup>
            {lunchChoice.map((item, index) => (
              <ListGroup.Item key={index}>{item}</ListGroup.Item>
            ))}
          </ListGroup>
        </Card.Body>
      </Card>

      <Card className='mt-2 text-center choice-back'>
        <Card.Body>
          <Card.Title>Dinner</Card.Title>
          <ListGroup>
            {dinnerChoice.map((item, index) => (
              <ListGroup.Item key={index}>{item}</ListGroup.Item>
            ))}
          </ListGroup>
        </Card.Body>
      </Card>

      <Card className='mt-2 text-center choice-back'>
        <Card.Body>
          <Card.Title>Snack</Card.Title>
          <ListGroup>
            {snackChoice.map((item, index) => (
              <ListGroup.Item key={index}>{item}</ListGroup.Item>
            ))}
          </ListGroup>
        </Card.Body>
      </Card>

      {jwtToken !== '' && (
        <Fragment>
          <hr />

          <Row>
            <Col>
              <Button variant='success' style={{ width: '150px' }} onClick={() => handleEatenClick('breakfast')}>
                <strong>Clear Choices</strong>
              </Button>
            </Col>
          </Row>
        </Fragment>
      )}
    </Container>
  )
}

export default Choices
