import { Fragment } from 'react'

export default function Home() {
  return (
    <Fragment>
        <h1>Welcome to Web Menu</h1>

        <p>Application is running in {process.env.NODE_ENV === 'development' ? 'development mode' : 'production mode'} </p>
    </Fragment>
  )
}
