import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Container, Row, Col, Form, Button } from 'react-bootstrap'
import { useNavigate, useOutletContext } from 'react-router-dom'
import Alert from './Alert'

interface Errors {
  [key: string]: string
}

function EditItem() {
  // const [meal, setMeal] = useState<InventoryItem>()
  const [errors, setErrors] = useState<Errors>({})
  const [validForm, setValidForm] = useState<boolean>(false)
  const [mealId, setMealId] = useState<string>('')
  const [mealName, setMealName] = useState<string>('')
  const [mealQty, setMealQty] = useState<string>('')
  const [mealFilename, setMealFilename] = useState<string>('')
  const [mealBreakfast, setMealBreakfast] = useState<boolean>(false)
  const [mealLunch, setMealLunch] = useState<boolean>(false)
  const [mealDinner, setMealDinner] = useState<boolean>(false)
  const [mealSnack, setMealSnack] = useState<boolean>(false)
  const [mealDrink, setMealDrink] = useState<boolean>(false)
  const [mealRestaraunt, setMealRestaraunt] = useState<boolean>(false)
  const [mealBrand, setMealBrand] = useState<string>('')
  const [mealStore, setMealStore] = useState<string>('')
  const [mealRating, setMealRating] = useState<string>('')
  const [createdDate, setCreatedDate] = useState<string>('')
  const [modifiedDate, setModifiedDate] = useState<string>('')

  const { jwtToken } = useOutletContext<any>()
  const navigate = useNavigate()

  let path = window.location.href
  let id = path.substring(path.lastIndexOf('/'))

  useEffect(() => {
    async function getItem() {
      if (jwtToken === '') {
        navigate('/login')
        return
      }

      try {
        const resp = await axios.get(
          `${
            process.env.NODE_ENV === 'development' ? process.env.REACT_APP_BACKEND_DEV : process.env.REACT_APP_BACKEND_PROD
          }/admin/edit${id}`,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + jwtToken,
            },
            withCredentials: true,
          }
        )

        setMealId(resp.data.id)
        setMealName(resp.data.item_name)
        setMealQty(resp.data.quantity)
        setMealFilename(resp.data.item_filename)
        setMealBreakfast(resp.data.breakfast)
        setMealLunch(resp.data.lunch)
        setMealDinner(resp.data.dinner)
        setMealSnack(resp.data.snack)
        setMealDrink(resp.data.drink)
        setMealRestaraunt(resp.data.restaraunt)
        setMealBrand(resp.data.brand)
        setMealStore(resp.data.store)
        setMealRating(resp.data.rating)
        setCreatedDate(resp.data.created_at)
        setModifiedDate(resp.data.updated_at)
      } catch (error: any) {
        Alert(error)
      }
    }

    if (id !== '') {
      getItem()
    }
  }, [id, jwtToken, navigate])

  function handleSubmit(e: React.FormEvent) {
    e.preventDefault()
    setErrors({})

    if (mealName === '') {
      setErrors({ ...errors, name: 'Name cannot be blank' })
    }

    if (isNaN(parseInt(mealQty)) || parseInt(mealQty) < 0) {
      setErrors({ ...errors, qty: 'Quantity must be a positive number >= 0' })
    }

    if (mealFilename === '') {
      setErrors({ ...errors, filename: 'Filename cannot be blank' })
    }

    if (!/^\d+$/.test(mealRating) || parseInt(mealRating) < 0 || parseInt(mealRating) > 5) {
      setErrors({ ...errors, rating: 'Rating must be a positive number >= 0 and <= 5' })
    }

    if (mealBrand === '') {
      setErrors({ ...errors, brand: 'Brand cannot be blank' })
    }

    if (mealStore === '') {
      setErrors({ ...errors, store: 'Store cannot be blank' })
    }

    if (errors) {
      setValidForm(false)
      return
    }

    setValidForm(true)
  }

  return (
    <Container>
      <Row>
        <h1>Edit an item</h1>
      </Row>

      <Form onSubmit={(e) => handleSubmit(e)} noValidate validated={validForm}>
        <Form.Label htmlFor='mealId'>
          <strong>ID</strong>: {mealId}{' '}
        </Form.Label>

        <Row className='mt-2'>
          <Col>
            <Form.Group>
              <Form.Label htmlFor='mealName'>
                <strong>Name:</strong>
              </Form.Label>
              <Form.Control
                size='sm'
                id='mealName'
                type='text'
                value={mealName}
                style={{ width: '300px' }}
                onChange={(e) => setMealName(e.target.value)}
                isInvalid={!!errors.name}
              />
              {errors.name && <Form.Control.Feedback type='invalid'>{errors.name}</Form.Control.Feedback>}
            </Form.Group>
          </Col>

          <Col>
            <Form.Group>
              <Form.Label htmlFor='mealFilename'>
                <strong>Filename:</strong>
              </Form.Label>
              <Form.Control
                size='sm'
                id='mealFilename'
                type='text'
                value={mealFilename}
                style={{ width: '300px' }}
                onChange={(e) => setMealFilename(e.target.value)}
                isInvalid={!!errors.filename}
              />
              {errors.filename && <Form.Control.Feedback type='invalid'>{errors.filename}</Form.Control.Feedback>}
            </Form.Group>
          </Col>

          <Col>
            <Form.Group>
              <Form.Label htmlFor='mealQty'>
                <strong>Quantity:</strong>
              </Form.Label>
              <Form.Control
                size='sm'
                id='mealQty'
                type='text'
                value={mealQty}
                style={{ width: '60px' }}
                onChange={(e) => setMealQty(e.target.value)}
                isInvalid={!!errors.qty}
              />
              {errors.qty && <Form.Control.Feedback type='invalid'>{errors.qty}</Form.Control.Feedback>}
            </Form.Group>
          </Col>
        </Row>

        <Form.Group className='mt-2'>
          <Row>
            <Col>
              <Form.Check
                type='checkbox'
                checked={mealBreakfast}
                label='Breakfast'
                onChange={() => setMealBreakfast(!mealBreakfast)}
              />
            </Col>

            <Col>
              <Form.Check type='checkbox' checked={mealLunch} label='Lunch' onChange={() => setMealLunch(!mealLunch)} />
            </Col>

            <Col>
              <Form.Check type='checkbox' checked={mealDinner} label='Dinner' onChange={() => setMealDinner(!mealDinner)} />
            </Col>
          </Row>

          <Row>
            <Col>
              <Form.Check type='checkbox' checked={mealSnack} label='Snack' onChange={() => setMealSnack(!mealSnack)} />
            </Col>

            <Col>
              <Form.Check type='checkbox' checked={mealDrink} label='Drink' onChange={() => setMealDrink(!mealDrink)} />
            </Col>

            <Col>
              <Form.Check
                type='checkbox'
                checked={mealRestaraunt}
                label='Restaraunt'
                onChange={() => setMealRestaraunt(mealRestaraunt)}
              />
            </Col>
          </Row>
        </Form.Group>

        <Row className='mt-2'>
          <Col>
            <Form.Group>
              <Form.Label htmlFor='mealBrand'>
                <strong>Brand:</strong>
              </Form.Label>
              <Form.Control
                size='sm'
                id='mealBrand'
                type='text'
                value={mealBrand}
                style={{ width: '300px' }}
                onChange={(e) => setMealBrand(e.target.value)}
                isInvalid={!!errors.brand}
              />
              {errors.brand && <Form.Control.Feedback type='invalid'>{errors.brand}</Form.Control.Feedback>}
            </Form.Group>
          </Col>

          <Col>
            <Form.Group>
              <Form.Label htmlFor='mealStore'>
                <strong>Brand:</strong>
              </Form.Label>
              <Form.Control
                size='sm'
                id='mealStore'
                type='text'
                value={mealStore}
                style={{ width: '300px' }}
                onChange={(e) => setMealStore(e.target.value)}
                isInvalid={!!errors.store}
              />
              {errors.store && <Form.Control.Feedback type='invalid'>{errors.store}</Form.Control.Feedback>}
            </Form.Group>
          </Col>

          <Col>
            <Form.Group>
              <Form.Label htmlFor='mealStore'>
                <strong>Rating:</strong>
              </Form.Label>
              <Form.Control
                size='sm'
                id='mealRating'
                type='text'
                value={mealRating}
                style={{ width: '60px' }}
                onChange={(e) => setMealRating(e.target.value)}
                isInvalid={!!errors.rating}
              />
              {errors.rating && <Form.Control.Feedback type='invalid'>{errors.rating}</Form.Control.Feedback>}
            </Form.Group>
          </Col>
        </Row>

        <Form.Group className='mt-3'>
          <Row>
            <Col>
              <Form.Label htmlFor='mealCreatedDate'>
                <strong>Created Date:</strong> {createdDate.toString()}
              </Form.Label>
            </Col>

            <Col>
              <Form.Label htmlFor='mealmodifiedDate'>
                <strong>Modified Date:</strong> {modifiedDate.toString()}
              </Form.Label>
            </Col>
          </Row>
        </Form.Group>

        <Button className='mt-2' type='submit'>
          Submit
        </Button>
      </Form>
    </Container>
  )
}

export default EditItem
