import { Fragment, useRef, useState } from 'react'
import { Row, Col } from 'react-bootstrap'
import { useNavigate, useOutletContext } from 'react-router-dom'
import Input from './form/input'
import Alert from './Alert'
import axios from 'axios'

function Login() {
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')

  const { setJwtToken } = useOutletContext<any>()
  const { toggleRefresh } = useOutletContext<any>()

  const navigate = useNavigate()

  const emailRef = useRef()
  const passwordRef = useRef()

  const handleSubmit = async (e: any) => {
    e.preventDefault()

    //  build request payload
    let payload = {
      email: email,
      password: password,
    }

    try {
      const resp = await axios.post(
        `${
          process.env.NODE_ENV === 'development' ? process.env.REACT_APP_BACKEND_DEV : process.env.REACT_APP_BACKEND_PROD
        }/authenticate`,
        JSON.stringify(payload),
        {
          headers: {
            'Content-Type': 'application/json',
          },
          withCredentials: true,
        }
      )

      if (resp.data.error) {
        Alert(resp.data.message)
      } else {
        setJwtToken(resp.data.access_token)
        toggleRefresh(true)
        navigate('/')
      }
    } catch (err: any) {
      Alert(err)
    }
  }

  return (
    <Fragment>
      <Row className='justify-content-md-center'>
        <Col className='d-flex align-items-center justify-content-center'>
          <h3>Login</h3>
        </Col>
      </Row>

      <form onSubmit={(e) => handleSubmit(e)}>
        <Row className='justify-content-md-center mt-2'>
          <Col className='d-flex align-items-center justify-content-center'>
            <Input
              title='Email Address'
              type='email'
              className='form-control'
              name='email'
              autoComplete='email-new'
              onChange={(e: any) => setEmail(e.target.value)}
              value={email}
              placeHolder='Enter Email Address'
              ref={emailRef}
            />
          </Col>
        </Row>

        <Row className='justify-content-md-center mt-2'>
          <Col className='d-flex align-items-center justify-content-center'>
            <Input
              title='Password'
              type='password'
              className='form-control'
              name='password'
              autoComplete='password-new'
              onChange={(e: any) => setPassword(e.target.value)}
              value={password}
              placeHolder='Enter password'
              ref={passwordRef}
            />
          </Col>
        </Row>

        <Row className='justify-content-md-center mt-2'>
          <Col className='d-flex align-items-center justify-content-center'>
            <input type='submit' className='btn btn-primary' value='Submit' />
          </Col>
        </Row>
      </form>
    </Fragment>
  )
}

export default Login
