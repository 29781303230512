import ReactDOM from 'react-dom/client'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import App from './App'
import reportWebVitals from './reportWebVitals'
import ErrorPage from './components/ErrorPage'
import Choices from './components/Choices'
import Inventory from './components/Inventory'
import Login from './components/Login'
import EditItem from './components/EditItem'
import Meal from './components/Meal'
import Home from './components/home'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.min'
import './index.css'

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      { index: true, element: <Home /> },
      {
        path: '/choices',
        element: <Choices />
      },
      {
        path: '/meal/breakfast',
        element: <Meal screenType='breakfast' title='Breakfast' />,
      },
      {
        path: '/meal/lunch',
        element: <Meal screenType='lunch' title='Lunch' />,
      },
      {
        path: '/meal/dinner',
        element: <Meal screenType='dinner' title='Dinner' />,
      },
      {
        path: '/meal/snack',
        element: <Meal screenType='snack' title='Snack' />,
      },
      {
        path: '/meal/drink',
        element: <Meal screenType='drink' title='Drink' />,
      },
      {
        path: '/meal/restaraunt',
        element: <Meal screenType='restaraunt' title='Restaraunt' />,
      },
      {
        path: '/inventory',
        element: <Inventory screenType='inventory' title='Inventory' />,
      },
      {
        path: '/login',
        element: <Login />,
      },
      {
        path: '/edit/:id',
        element: <EditItem />,
      },
    ],
  },
])

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

root.render(
  <RouterProvider router={router} />
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
