import { forwardRef } from 'react'

interface Props {
  name: string
  title: string
  type: string
  className: string
  placeHolder: string
  onChange: any
  autoComplete: string
  value: string
}

const Input = forwardRef(
  ({ name, title, type, className, placeHolder, onChange, autoComplete, value, }: Props, ref: any) => {
    return (
      <div className='login-back'>
        <label htmlFor={name} className='form-label'>
          <strong>{title}</strong>
        </label>

        <input
          style={{ width: '300px', display: 'block', margin: '0 auto' }}
          type={type}
          className={className}
          id={name}
          name={name}
          placeholder={placeHolder}
          onChange={onChange}
          autoComplete={autoComplete}
          value={value}
        />
      </div>
    )
  }
)

export default Input
